import React, { lazy, Suspense, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import NotFound from "./components/not-found/not-found";
import {
  excelExtensions,
  imageExtensions,
  docExtensions,
  pptExtensions,
  codeExtensions,
  lottieExtensions,
  ebookExtensions,
  audioExtensionsBrowserNative,
  latexExtensions,
} from "./util/file";
import "./index.css";
import { Spinner } from "react-bootstrap";

const FilesView = lazy(() => import("./components/files-view/files-view"));
const PDFRender = lazy(() => import("./components/renderer/PdfViewer"));
const ExcelViewer = lazy(() => import("./components/renderer/ExcelViewer"));
const ImageViewer = lazy(() => import("./components/renderer/ImageViewer"));
const CodeViewer = lazy(() => import("./components/renderer/CodeViewer"));
const LottieViewer = lazy(() => import("./components/renderer/LottieViewer"));
const AudioViewer = lazy(() => import("./components/renderer/AudioViewer"));
const EbookViewer = lazy(() => import("./components/renderer/EbookViewer"));

export const BASE_DEPLOY_PATH = "https://tiiny.host/c-ui";

function RedirectRouter() {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.fileList?.length === 1) {
      const file = window.fileList[0];
      const fileExt = file.split(".").pop().toLowerCase();
      let path = file.split(".").slice(0, -1).join("");

      if (fileExt === "pdf") {
        const fileExts = file?.split(".").slice(-3);
        if (fileExts.some((ext) => pptExtensions.includes(ext))) {
          path = file.split(".").slice(0, -1).join(".");
        } else if (fileExts.some((ext) => docExtensions.includes(ext))) {
          path = file.split(".").slice(0, -1).join(".");
        } else if (fileExts.some((ext) => latexExtensions.includes(ext))) {
          path = file.split(".").slice(0, -1).join(".");
        }
        return navigate(`/pdf/${path}`);
      } else if (fileExt === "epub") {
        const fileExts = file?.split(".").slice(-3);
        if (fileExts.some((ext) => ebookExtensions.includes(ext))) {
          path = file.split(".").slice(0, -1).join(".");
        }
        return navigate(`/epub/${path}`);
      } else {
        switch (true) {
          case excelExtensions.includes(fileExt):
            navigate(`/${fileExt}/${path}`);
            break;
          case imageExtensions.includes(fileExt):
            navigate(`/${fileExt}/${path}`);
            break;
          case lottieExtensions.includes(fileExt):
            navigate(`/${fileExt}/${path}`);
            break;
          case codeExtensions.includes(fileExt):
            navigate(`/${fileExt}/${path}`);
            break;
          case ebookExtensions.includes(fileExt):
            navigate(`/${fileExt}/${path}`);
            break;
          case audioExtensionsBrowserNative.includes(fileExt):
            navigate(`/${fileExt}/${path}`);
            break;
          default:
            navigate("/");
        }
      }
    }
  }, [navigate]);

  return null;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <RedirectRouter />
    <Suspense
      fallback={
        <div className="loading-container">
          <Spinner animation="border" variant="dark">
            <span className="visually-hidden"></span>
          </Spinner>
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<FilesView />} />
        <Route path="/pdf/*" element={<PDFRender />} />
        {excelExtensions.map((ext) => (
          <Route key={ext} path={`/${ext}/*`} element={<ExcelViewer />} />
        ))}
        {imageExtensions.map((ext) => (
          <Route key={ext} path={`/${ext}/*`} element={<ImageViewer />} />
        ))}
        {lottieExtensions.map((ext) => (
          <Route key={ext} path={`/${ext}/*`} element={<LottieViewer />} />
        ))}
        {codeExtensions.map((ext) => (
          <Route key={ext} path={`/${ext}/*`} element={<CodeViewer />} />
        ))}
        {ebookExtensions.map((ext) => (
          <Route key={ext} path={`/${ext}/*`} element={<EbookViewer />} />
        ))}
        {audioExtensionsBrowserNative.map((ext) => (
          <Route key={ext} path={`/${ext}/*`} element={<AudioViewer />} />
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>

    <style jsx>{`
      .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(90vh - 50px);
      }
    `}</style>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
