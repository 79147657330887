import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {
  Card,
  Row,
  Col,
  InputGroup,
  FormControl,
  ButtonGroup,
  ToggleButton,
  Table
} from "react-bootstrap";
import CustomTooltip from "../custom-tooltip/custom-tooltip";
import {BASE_DEPLOY_PATH} from "../../index";
import {
  excelExtensions,
  imageExtensions,
  docExtensions,
  pptExtensions,
  isImageFile,
  isDocFile,
  isExcelFile,
  isPptFile
} from "../../util/file";

import styles from "./card-view.module.css";

const TRUNCATE_LENGTH = 20;
const truncate = (filename) => {
  const fileNameParts = filename.split("/");
  const label =
    fileNameParts.length > 1
      ? fileNameParts.slice(1, fileNameParts.length).join("/")
      : filename;
  if (label.length > TRUNCATE_LENGTH) {
    return `${label.substr(0, TRUNCATE_LENGTH / 2)}...${label.substr(
      label.length - TRUNCATE_LENGTH / 2 - 5,
      label.length
    )}`;
  }
  return label;
};

const getDisplayFileName = (fileName) => {
  const fileExts = fileName.split('.');
  if (fileExts.length > 2) {
    return fileExts.slice(0, -2).join('.');
  }
  return fileName;
};

const getFileIcon = (filepath) => {
  const fileExt = getDisplayFileName(filepath).split(".").pop();
  if (fileExt === "pdf") {
    return `${BASE_DEPLOY_PATH}/assets/pdf.png`;
  } else {
    if (isImageFile(getDisplayFileName(filepath))) {
      return `${BASE_DEPLOY_PATH}/assets/image.png`;
    } else if (isDocFile(getDisplayFileName(filepath))) {
      return `${BASE_DEPLOY_PATH}/assets/doc.png`;
    } else if (isExcelFile(getDisplayFileName(filepath))) {
      return `${BASE_DEPLOY_PATH}/assets/excel.png`;
    } else if (isPptFile(getDisplayFileName(filepath))) {
      return `${BASE_DEPLOY_PATH}/assets/ppt.png`;
    }  else {
      return `${BASE_DEPLOY_PATH}/assets/file.png`;
    }
  }
};

export default function FilesView() {
  const fileList = window.fileList?.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  const [searchTerm, setSearchTerm] = useState("");
  const [isListView, setIsListView] = useState(fileList?.length > 9); // State to toggle between grid and list view
  // const fileList = [
  //   "multi-pdf/tb1.pdf",
  //   "image.png",
  //   "tb2.pdf",
  //   "multi-pdf/emerging support interactive.pdf",
  //   "hello.note",
  // ];
  //window.showAd = true

  let navigate = useNavigate();

  const updateUrlPath = ({ file }) => {
    const fileExt = file.split(".").pop().toLowerCase();
    let path = file.split(".").slice(0, -1).join("");

    if (fileExt === "pdf") {
      const fileExts = file?.split(".").slice(-3);
      if (fileExts.some((ext) => pptExtensions.includes(ext))) {
        path = file.split(".").slice(0, -1).join(".");
      } else if (fileExts.some((ext) => docExtensions.includes(ext))) {
        path = file.split(".").slice(0, -1).join(".");
      }
      return navigate(`/pdf/${path}`);
    } else {
      switch (true) {
        case excelExtensions.includes(fileExt):
          return navigate(`/${fileExt}/${path}`);
        case imageExtensions.includes(fileExt):
          return navigate(`/${fileExt}/${path}`);
      }
    }

    window.open(file);
  };

  const onCopyClick = (e, filename) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(`${window.location.href}${filename}`);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleToggleView = () => {
    setIsListView(!isListView);
  };

  // Filter files based on search term
  const filteredFiles = fileList.filter((fileName) =>
    fileName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.container}>
      <div>
        <div>
          {window.showAd && (
            <img
              height={75}
              src="https://tiiny.host/ad.png"
              alt="tiiny.host"
            />
          )}
          <h1>Collection</h1>
        </div>
        <div className="d-flex align-items-center mt-4">
          <InputGroup className="mr-3 mb-2">
            <FormControl
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>
          <ButtonGroup>
            <ToggleButton
              key={1}
              id={`radio-listview`}
              type="radio"
              variant="outline-secondary"
              name="radio"
              value={1}
              checked={isListView}
              onChange={handleToggleView}
            >
              <i className="bi bi-list"></i>
            </ToggleButton>
            <ToggleButton
              key={2}
              id={`radio-gridview`}
              type="radio"
              variant="outline-secondary"
              name="radio"
              value={2}
              checked={!isListView}
              onChange={handleToggleView}
            >
              <i className="bi bi-grid"></i>
            </ToggleButton>
          </ButtonGroup>
        </div>
      </div>
      {isListView ? (
        <Table striped hover>
          <thead>
          <tr>
            <th>Name</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {filteredFiles.map((fileName, key) => (
            <tr
              key={key + 1}
              onClick={() => updateUrlPath({file: fileName})}
              className={styles.fileRow}
            >
              <td>
                <img
                  alt="File icon"
                  height={25}
                  src={getFileIcon(fileName)}
                  className="mr-3"
                /> {getDisplayFileName(fileName)}
              </td>
              <td>
                <CustomTooltip label="Copy link">
                  <button
                    className={styles.copyBtn}
                    onClick={(e) => onCopyClick(e, fileName)}
                  >
                    <img
                      src={`${BASE_DEPLOY_PATH}/assets/link.png`}
                      height={20}
                      alt="Copy link"
                    />
                  </button>
                </CustomTooltip>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      ) : (
        <>
          <hr/>
          <Row xs={1} md={2}>
            {filteredFiles.map((fileName, key) => (
              <Col
                className={`col-xs-8 col-md-6 col-lg-4 ${styles.col}`}
                key={key + 1}
              >
                <Card
                  className={styles.card}
                  onClick={() => updateUrlPath({file: fileName})}
                >
                  <Card.Body className={styles.cardBody}>
                    <CustomTooltip label="Copy link">
                      <button
                        className={`${styles.copyBtn} ${styles.topRight}`}
                        onClick={(e) => onCopyClick(e, fileName)}
                      >
                        <img
                          src={`${BASE_DEPLOY_PATH}/assets/link.png`}
                          height={15}
                          alt="Copy link"
                        />
                      </button>
                    </CustomTooltip>
                    <Card.Img
                      className={styles.icon}
                      variant="top"
                      src={getFileIcon(fileName)}
                    />
                    {getDisplayFileName(fileName).length > TRUNCATE_LENGTH ? (
                      <CustomTooltip label={getDisplayFileName(fileName)}>
                        <div className={styles.title}>{truncate(getDisplayFileName(fileName))}</div>
                      </CustomTooltip>
                    ) : (
                      <div className={styles.title}>{truncate(getDisplayFileName(fileName))}</div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
      <hr/>
    </div>
  );
}


