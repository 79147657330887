import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { imageExtensions, getFileCategoryType } from "../../util/file";

function ImageViewer({ filename }) {
  const [content, setContent] = useState("");
  let location = useLocation();

  useEffect(() => {
    const filePath = location.pathname;
    const fileCategory = getFileCategoryType(filePath.split("/")[1]);

    const BASE_URL = window.location.origin;
    const FILE_URL = `${BASE_URL}${filePath}.${filePath.split("/")[1]}.${fileCategory}.png`;
    
    const fetchURL = imageExtensions.reduce(
      (url, ext) => url.replace(`${ext}/`, ""),
      FILE_URL
    );
    setContent(fetchURL);
  }, [filename, location.pathname]);

  return (
    <div className="image-wrapper">
      {content && <img src={content} alt={filename} />}
      <style jsx>{`
        .image-wrapper {
          max-width: 100%;
          max-height: 90vh;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          max-width: 100%;
          max-height: 90vh;
          object-fit: contain;
        }
      `}</style>
    </div>
  );
}

export default ImageViewer;
